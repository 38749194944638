@import url("https://fonts.googleapis.com/css?family=Charmonman|Roboto|K2D");
#register-area {
  min-height: 510px;
  font-family: "K2D", sans-serif;
  flex: 1;
}
.register-body {
  position: absolute;
  width: 100%;
  top:0;
  bottom: 0;
}
.register-showcase {
  display:  flex;
  flex-direction: column;
  height: 100%;
}
.register-box {
  width: 280px;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 150px;
}

.register-box h1 {
  font-size: 40px;
  border-bottom: 2px solid gray;
}

.register-textbox {
  width: 100%;
  font-size: 30px;
  overflow: hidden;
  padding: 8px 0px;
  margin: 8px 0px;
  /* border-bottom: 1px solid lightcoral;

   */
}

.register-textbox input {
  border: none;
  outline: none;
  background: none;
  color: black;
  font-size: 20px;
  float: left;
  width: 80%;
}

.register-textbox i {
  width: 40px;
  float: left;
  text-align: center;
  font-size: 24px;
}
@media (max-width: 348px) {
  .register-box h1 {
    font-size: 25px;
  }
  .register-box {
    margin-top: 250px;
  }
}
@media (max-width: 769px) {
  .register-box h1 {
    font-size: 25px;
    text-align: center;
  }
  .register-box {
    margin-top: 230px;
  }
  #register-area {
    min-height: 850px;
  }
}