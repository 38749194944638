@import url(https://fonts.googleapis.com/css?family=Charmonman|Roboto|K2D);
@import url(https://fonts.googleapis.com/css?family=Charmonman|Roboto|K2D);
@import url(https://fonts.googleapis.com/css?family=Charmonman|Roboto|K2D);
@import url(https://fonts.googleapis.com/css?family=Charmonman|Roboto|K2D);
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

#login-area {
  min-height: 500px;
  font-family: "K2D", sans-serif;
  flex: 1 1;
}
.login-showcase {
  display:  flex;
  flex-direction: column;
  height: 100%;
}
.login-body {
  position: absolute;
  width: 100%;
  top:0;
  bottom: 0;
}
.login-box {
  width: 280px;
  position: absolute;
  top: 25%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin-top: 100px;
}

.login-box h1 {
  font-size: 40px;
  border-bottom: 2px solid gray;
}

.login-textbox {
  width: 100%;
  font-size: 30px;
  overflow: hidden;
  padding: 8px 0px;
  margin: 8px 0px;
  border-bottom: 1px solid lightcoral;
}

.login-textbox input {
  border: none;
  outline: none;
  background: none;
  color: black;
  font-size: 20px;
  float: left;
  width: 80%;
  font-family: "K2D", sans-serif;
}

.login-textbox i {
  width: 40px;
  float: left;
  text-align: center;
  font-size: 24px;
}
footer {
  bottom: 0;
  position: fixed;
  width:100%
}
@media (max-width: 348px) {
  .login-box h1 {
    font-size: 25px;
  }
  .login-box {
    margin-top: 150px;
  }
}
@media (max-width: 769px) {
  .login-box h1 {
    font-size: 25px;
    text-align: center;
  }
  .login-box {
    margin-top: 150px;
  }
  #login-area  {
    min-height: 850px;
  }
}
body {
  margin: 0px;
  padding: 0px;
}

/* Global */

.container {
  margin: auto;
  width: 80%;
  overflow: hidden;
}
.landing-page {
  height: 100vh;
  display:  flex;
  flex-direction: column;
}
.btn {
  width: 100%;
  background: gray;
  border-bottom: 2px solid gray;
  border-radius: 50px;
  padding: 5px;
  margin: 12px 0px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  outline: none;
  color: white;
  font-family: "K2D", sans-serif;
}

.btn:hover {
  background-image: linear-gradient(to left, gray, violet);
}

/* Header */

header {
  min-height: 70px;
  border-bottom: 1px solid white;
}

header a {
  text-decoration: none;
  color: gray;
  font: normal 22px "K2D", sans-serif;
}

header ul {
  margin: 0px;
  padding: 0px;
}

header li {
  display: inline;
  padding: 0px 20px 0px 10px;
}

#header-title {
  float: left;
}

#header-title img {
  margin-top: 5px;
  height: 50px;
}

header nav {
  float: right;
  margin-top: 10px;
}

/* Showcase */

#showcase {
  min-height: 510px;
  background: url(/static/media/home_background.a986647b.jpg) no-repeat left center;
  background-size: cover;
  color: white;
  text-align: center;
  font-family: "K2D", sans-serif;
  flex: 1 1;
}

#showcase h1 {
  font-size: 80px;
  margin: 100px 0px 10px 0px;
  font-weight: bold;
}

#showcase p {
  font-size: 33px;
}

/* Footer */

footer {
  font-family: "K2D", sans-serif;
  background-image: linear-gradient(
    to top,
    rgb(73, 74, 76),
    rgb(181, 189, 201)
  );
  min-height: 65px;
}

footer h3 {
  text-align: center;
  color: white;
}

/* Media Queries */

@media (max-width: 768px) {
  header #header-title,
  header nav,
  header nav li {
    float: none;
    width: 100%;
    text-align: center;
  }
  header a {
    text-align: center;
    font-size: 17px;
  }
  #showcase  {
    min-height: 850px;
    margin-top:30px;
  }
  #showcase h1 {
    font-size: 60px;
  }
}

/* product area */


.product-area {
    min-height: 400px;
    flex: 1 1;
}

.product-area h1 {
    text-align: center;
    color: black;
    font: normal bold 40px 'K2D', sans-serif;
}

.products {
    margin: 20px;
}

.product-item {
    float: left;
    width: 30%;
    padding: 10px;
    text-align: center;
    overflow: hidden;
    border: 1px solid gray;
}
.product-item p {
  font-weight: bold;
  font-family: 'K2D', sans-serif;
}
.product-item img {
    height: 150px;
    cursor: pointer;
    transition: all 1s ease;
}

.product-item img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}

.editBtn {
    background: gray;
    border: 0px;
    cursor: pointer;
    outline: none;
    font-size: 16px;
    height: 40px;
    width: 100px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0px;
}

.editBtn a {
     color: white;
    text-decoration: none;
}

 .deleteBtn {
    background: rgb(228, 66, 66);
    color: white;
    border: 0px;
    cursor: pointer;
    outline: none;
    font-size: 16px;
    height: 40px;
    width: 100px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0px;
}
@media (max-width: 879px) {
    .products .product-item {
       float: none;
       width: 100%;
       border: none;
    }
    header #header-title,
    header nav,
    header nav li {
        float: none;
        width: 100%;
        text-align: center;
    }
    header a {
        text-align: center;
        font-size: 17px;
    }
  }

#register-area {
  min-height: 510px;
  font-family: "K2D", sans-serif;
  flex: 1 1;
}
.register-body {
  position: absolute;
  width: 100%;
  top:0;
  bottom: 0;
}
.register-showcase {
  display:  flex;
  flex-direction: column;
  height: 100%;
}
.register-box {
  width: 280px;
  position: absolute;
  top: 25%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin-top: 150px;
}

.register-box h1 {
  font-size: 40px;
  border-bottom: 2px solid gray;
}

.register-textbox {
  width: 100%;
  font-size: 30px;
  overflow: hidden;
  padding: 8px 0px;
  margin: 8px 0px;
  /* border-bottom: 1px solid lightcoral;

   */
}

.register-textbox input {
  border: none;
  outline: none;
  background: none;
  color: black;
  font-size: 20px;
  float: left;
  width: 80%;
}

.register-textbox i {
  width: 40px;
  float: left;
  text-align: center;
  font-size: 24px;
}
@media (max-width: 348px) {
  .register-box h1 {
    font-size: 25px;
  }
  .register-box {
    margin-top: 250px;
  }
}
@media (max-width: 769px) {
  .register-box h1 {
    font-size: 25px;
    text-align: center;
  }
  .register-box {
    margin-top: 230px;
  }
  #register-area {
    min-height: 850px;
  }
}
